<template>
  <el-dialog class="dialog-salecard" :visible.sync="sellShow" :modal="false" :title="$t('market.status.withdrawtitle')"
             center>
    <el-form>
      <el-form-item>
        <div class="items item2 align-center flex-between">
          <div class="relative" style="width: 90px; height: 130px" v-if="sellObj.type == 'card'">
            <!-- 背景图 -->
            <img v-if="sellObj.attrs && sellObj.attrs.herostar && sellObj.attrs.grade" :src="require('@/assets/card/itemBigEx' +
              borderStatus(sellObj.attrs.herostar, sellObj.attrs.grade) +
              '.png')
              " width="77.5px" height="122.5px" class="imgbg"/>
            <!-- 英雄图片 -->
            <img v-if="sellObj.attrs && sellObj.attrs.heroid"
                 :src="require('@/assets/hero/' + sellObj.attrs.heroid + '.png')" width="80px" height="125px"
                 class="cardimg"/>
            <!-- 边框 -->
            <img v-if="sellObj.attrs && sellObj.attrs.herostar && sellObj.attrs.grade" :src="require('@/assets/card/bg_kuang' +
              borderStatus(sellObj.attrs.herostar, sellObj.attrs.grade) +
              '.png')
              " width="90px" height="130px" class="borders"/>
            <!-- 级别 -->
            <div class="grade_img d-flex flex-column">
              <img v-if="sellObj.attrs && sellObj.attrs.grade" :src="require('@/assets/card/' +
                gradeStatus(sellObj.attrs.grade) +
                '.png')
                " width="25px"/>
              <img v-if="sellObj.attrs && sellObj.attrs.is_sp == 1" :src="require('@/assets/card/sp.png')" width="25px"
                   class="m-t-10"/>
            </div>
            <!-- 名字和星级 -->
            <div class="price t-color4 bold fs-md t-center">
              <div class="star d-flex ai-center jc-center" v-if="sellObj.attrs">
                <div class="d-flex ai-center" v-if="sellObj.attrs.herostar <= 5">
                  <img src="@/assets/card/star1big.png" width="8px" v-for="(sellObj, i) in sellObj.attrs.herostar"
                       :key="i"/>
                </div>
                <div class="d-flex ai-center" v-if="sellObj.attrs.herostar > 5 && sellObj.attrs.herostar <= 9">
                  <img src="@/assets/card/star2big.png" width="8px" v-for="(sellObj, i) in sellObj.attrs.herostar - 5"
                       :key="i"/>
                </div>
                <div class="d-flex ai-center" v-if="sellObj.attrs.herostar > 9 && sellObj.attrs.herostar <= 11">
                  <img src="@/assets/card/star3big.png" width="8px" v-for="(sellObj, i) in sellObj.attrs.herostar - 9"
                       :key="i"/>
                </div>
              </div>
            </div>
            <!-- 种族 -->
            <img v-if="sellObj.attrs && sellObj.attrs.herocamp" :src="require('@/assets/card/bigcamp' +
              sellObj.attrs.herocamp +
              '.png')
              " width="20px" class="camp_img"/>
            <!-- 职业 -->
            <img v-if="sellObj.attrs && sellObj.attrs.profession_type" :src="require('@/assets/card/type' +
              sellObj.attrs.profession_type +
              '.png')
              " width="20px" class="pros_img"/>
          </div>
          <div class="img relative" v-if="sellObj.type == 'dragon'">
            <div class="jiaobiao" v-if="sellObj.tokenid"><span>#{{ sellObj.tokenid }}</span></div>
            <!-- <img :src="require(`@/assets/dragon/dragon${sellObj.artifactid}.png`)" class="dragon" /> -->
            <img v-if="sellObj.artifactid == 1"
                 :src="require(`@/assets/dragon/fireDragon/dragon (${sellObj.images}).png`)" class="dragon"/>
            <img v-if="sellObj.artifactid == 2"
                 :src="require(`@/assets/dragon/iceDragon/dragon (${sellObj.images}).png`)"
                 class="dragon"/>
            <img v-if="sellObj.artifactid == 3"
                 :src="require(`@/assets/dragon/windDragon/dragon (${sellObj.images}).png`)" class="dragon"/>
            <img v-if="sellObj.artifactid == 4"
                 :src="require(`@/assets/dragon/lightDragon/dragon (${sellObj.images}).png`)" class="dragon"/>
            <img v-if="sellObj.artifactid == 5"
                 :src="require(`@/assets/dragon/darkDragon/dragon (${sellObj.images}).png`)" class="dragon"/>
          </div>
          <div class="relative hidden-sm-and-up"
               style="width: 100px; height: 130px; line-height: 26px; text-align: right;">
            <p class=" t-color4" v-if="sellObj.type == 'card'">
              <span v-if="sellObj.attrs">Lv.{{ sellObj.attrs.herolevel }}</span>
              <span v-if="sellObj.hero_name">{{ sellObj.hero_name }}</span>
            </p>
            <p class="t-color4" v-else>
              <span v-if="sellObj.level">Lv.{{ sellObj.level }}</span>
              <span v-if="sellObj.dragonname"> {{ sellObj.dragonname }}</span>
            </p>
            <p><span>Token ID</span></p>
            <p><span class="t-color8">#{{ sellObj.tokenid || '--' }}</span></p>
            <p><span>{{ $t('trans.holder') }}</span></p>
            <p><span class="t-color8">#{{ addressFilter(infos.address) }}</span></p>
          </div>
          <div class="relative hidden-xs-only" style="width: 168px; height: 130px">
            <p class=" t-color4" v-if="sellObj.type == 'card'">
              <span v-if="sellObj.attrs">Lv.{{ sellObj.attrs.herolevel }}</span>
              <span v-if="sellObj.hero_name">{{ sellObj.hero_name }}</span>
            </p>
            <p class="t-color4" v-else>
              <span v-if="sellObj.level">Lv.{{ sellObj.level }}</span>
              <span v-if="sellObj.dragonname"> {{ sellObj.dragonname }}</span>
            </p>
            <p class="clr"><span class="flt">Token ID</span><span class="frt t-color8">#{{
                sellObj.tokenid || '--'
              }}</span>
            </p>
            <p class="clr"><span class="flt">{{ $t('trans.holder') }}</span><span class="frt t-color8">#{{
                addressFilter(sellObj.address)
              }}</span></p>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="label">{{ $t("trans.address") }}</div>
        <div class="inpDiv">
          <el-input v-model="infos.address" autocomplete="off" class="address_input" readonly></el-input>
        </div>
      </el-form-item>
      <el-form-item class="tc">
        <el-button :loading="loading" :disabled="disabled"
                   :class="['w-100', sellObj.assets_status == 15 || sellObj.status == 15 ? 'hover' : 'notallow']"
                   @click="dataFun">{{
            $t("messageBox.confirm")
          }}
        </el-button>
      </el-form-item>
    </el-form>
    <p style="font-size: 12px; color: #f4ab0b; line-height: 22px">
      {{ $t('trans.withdrawTip') }}
    </p>
  </el-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      sellShow: false,
      loading: false,
      disabled: false,
    };
  },
  props: {
    sellObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    sellShow: {
      handler(n, o) {
        if (n) {
        } else {
          this.disabled = false
        }
      }
    }
  },
  mounted() {
  },
  computed: {
    ...mapState({
      sets: (state) => state.sets,
      infos: (state) => state.infos,
    }),
  },
  methods: {
    addressFilter(str) {
      if (!str) return "--";
      const str1 = str.substring(0, 5);
      const str2 = str.substring(str.length - 4, str.length);
      return str1 + "..." + str2;
    },
    borderStatus(val, grade) {
      switch (val) {
      case 1:
        return 1;
        break;
      case 2:
        return 2;
        break;
      case 3:
        return 3;
        break;
      case 4:
        return 4;
        break;
      case 5:
        return grade == "SSR" ? "5" : "5_1";
        break;
      case 6:
      case 7:
      case 8:
      case 9:
        return 6;
        break;
      default:
        return 7;
        break;
      }
    },
    gradeStatus(val) {
      switch (val) {
      case "R":
        return "r";
      case "SR":
        return "sr";
      case "SSR":
        return "ssr";
      case "SSR+":
        return "ssrjia";
      case "SP":
        return "sp";
      default:
        break;
      }
    },
    async dataFun() {
      this.loading = true
      let nftAddress;
      try {
        if (this.sellObj.type == 'card') {
          let getOwnerContract = new web3.eth.Contract(this.$store.state.cardsAbi, this.sets.hero_contract_address);

          let res = await getOwnerContract.methods.ownerOf(this.sellObj.tokenid).call({from: this.infos.address});
          if (this.sets.auction_contract_address.toLocaleLowerCase() == res.toLocaleLowerCase()) {
            nftAddress = this.sets.auction_contract_address;
          } else {
            nftAddress = this.sets.old_auction_contract_address;
          }
        }else{
          nftAddress = this.sets.auction_contract_address;
        }
        let auctionContract = new web3.eth.Contract(this.$store.state.auctionAbi, nftAddress)
        // console.log('withdrawNFT=>',1)
        auctionContract.methods.withdrawNFT(this.sellObj.type == 'card' ? this.sets.hero_contract_address : this.sets.artifact_contract_address, this.sellObj.tokenid)
            .send({from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE}, (error, result) => {
              console.log('withdrawNFT=>', error, result)
              this.loading = false
              if (error) {
                if (error.code == 4001) this.$message("User denied transaction signature");
                else this.$message(error.message);
              } else if (result) {
                this.$emit("callBack", {type: "withdrawDom", val: 'success'});
              }
            })
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  height: 38px;
  background: #3e3e4e;
  border: 1px solid #32363c;
  border-radius: 4px;
  color: #fff;
  padding: 0 15px;
}

.items {
  // width: 280px;
  height: 320px;
  background: #3e3e4e;
  border-radius: 4px;
  padding: 10px 20px;

  .name {
    font-size: 14px;
    font-weight: 400;
    color: #f3e9ca;
  }

  .img {
    width: 145px;
    height: 145px;
    background: url("../assets/account/bgframezs.png") no-repeat;
    background-size: 100% 98%;
    padding: 8px;

    .dragon {
      width: 100%;
      height: 100%;
    }

    .persale {
      position: absolute;
      right: -10px;
      top: -10px;
    }

    .jiaobiao {
      width: 54px;
      height: 54px;
      background: url("../assets/public/jiaobiao.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;

      span {
        display: block;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        right: 10px;
        color: #fff;
      }
    }

    .price {
      width: 170px;
      height: 30px;
      background: url("../assets/account/price.png") no-repeat;
      background-size: 100% 100%;
      bottom: 10px;
      line-height: 30px;
      font-size: 14px;
    }

    .block {
      width: 170px;
      height: 50px;
      background: url("../assets/account/block2.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 3px;
      left: 0;
      line-height: 50px;
      font-size: 14px;
    }
  }

  .opration {
    width: 180px;
    height: 38px;
    background: #317F6E;
    border-radius: 4px;
    line-height: 38px;
  }

  .hasactive2 {
    border: 1px solid #37B497;
    background: none;
  }

  .default {
    border: 1px solid #999999;
    background: transparent;
  }

  .activing {
    background: #999;
    cursor: wait;
  }

  .hasactive {
    border: 1px solid #999999 !important;
    background: transparent !important;
  }
}

.item2 {
  height: auto;

  .imgbg {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cardimg {
    position: relative;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .borders {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
  }

  .icon_box {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    background: url("../assets/card/card_jiao.png") no-repeat;
    background-size: 100% 100%;

    p {
      transform: rotate(-45deg);
      margin-top: 13%;
    }
  }

  .icon_box2 {
    background: url("../assets/card/card_jiao2.png") no-repeat;
    background-size: 100% 100%;
  }

  .icon_box3 {
    background: url("../assets/card/card_jiao3.png") no-repeat;
    background-size: 100% 100%;
  }

  .forsale {
    width: 160px;
    height: 90px;
    background: url("../assets/card/card_sell.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }

  .price {
    width: 100%;
    // height: 30px;
    line-height: 30px;
    background: url("../assets/account/price.png") no-repeat;
    background-size: 100% 100%;
    bottom: 15%;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 4;
    padding: 5px 0;
  }

  .grade_img {
    position: absolute;
    z-index: 4;
    top: 25px;
    right: 25px;
  }

  .camp_img {
    position: absolute;
    z-index: 4;
    bottom: -2px;
    left: -2px;
  }

  .pros_img {
    position: absolute;
    z-index: 4;
    bottom: -2px;
    right: -2px;
  }
}

.dialog-salecard {
  .el-form-item {
    margin-bottom: 5px;
  }
}</style>