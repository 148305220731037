<template>
  <el-dialog :visible.sync="captchaShow" center :modal="false">
    <!-- <div id="captcha"></div> -->
    <slide-verify :l="42" :r="10" :w="310" :h="155" @success="onSuccess" @fail="onFail"
      @refresh="onRefresh" :slider-text="$t('account.captip')"></slide-verify>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      captchaShow: false,
    };
  },
  mounted() { },
  methods: {
    onSuccess() {
      this.$emit("callBack", { type: "google", val: true });
      setTimeout(() => {
        this.captchaShow = false;
      }, 200);
    },
    onFail() {
      this.$emit("callBack", { type: "google", val: false });
    },
    onRefresh() {
      this.$emit("callBack", { type: "google", val: false });
    },
  },
};
</script>

<style>
</style>