<template>
  <el-dialog :visible.sync="codeShow" :title="$t('code.title')" center :modal="false">
    <p v-if="email" class="t-white lh-3" v-html="$t('code.content', { email: email })"></p>
    <el-form>
      <el-form-item v-if="email">
        <el-input v-model="codeForm.pwd" autocomplete="off" :placeholder="$t('code.w_code')"
          type="password" clearable></el-input>
      </el-form-item>
      <el-form-item class="m-t-5">
        <el-input v-model="codeForm.code" autocomplete="off" :placeholder="$t('code.code')"
          @keyup.enter="codeFun" clearable></el-input>
      </el-form-item>
      <el-form-item class="tc">
        <el-button :loading="loading" :class="['w-100']" @click="codeFun">{{
          $t("messageBox.confirm")
        }}</el-button>
      </el-form-item>
    </el-form>
    <p class="t-center" style="font-size: 12px">{{ $t("code.tip") }}</p>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      codeShow: false,
      loading: false,
      codeForm: {
        code: "",
        pwd: "",
      },
      email: "",
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    codeFun() {
      if (!this.codeForm.code) return this.$message(this.$t("code.p_code"));
      if (this.email) {
        if (!this.codeForm.pwd) return this.$message(this.$t("code.pwd_code"));
      }
      this.$storage.set("pwd", this.codeForm.pwd);
      this.loading = true;
      this.$emit("callBack", { type: "CheckCode", val: this.codeForm.code });
    },
    getInfos() {
      this.$store.dispatch("Get_infos").then((res) => {
        this.email = res.email;
      });
    },
  },
};
</script>

<style>
</style>