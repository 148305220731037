<template>
  <el-dialog :visible.sync="emailShow" :title="$t('email.title')" center :modal="false">
    <el-form>
      <el-form-item :label="$t('email.email')">
        <el-input v-model="emailForm.email" maxlength="30" autocomplete="off" :placeholder="$t('email.email')" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('pass.pass')">
        <el-input v-model="emailForm.password" autocomplete="off" :placeholder="$t('pass.p_pass')" show-password></el-input>
      </el-form-item>
      <el-form-item :label="$t('pass.conPass')">
        <el-input v-model="emailForm.newPass" autocomplete="off" :placeholder="$t('pass.p_conPass')" show-password @keyup.enter="emailFun"></el-input>
      </el-form-item>
      <el-form-item class="tc">
        <el-button :class="['w-100']" @click="emailFun">{{
          $t("messageBox.confirm")
        }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "BindEmail",
  data() {
    return {
      emailShow: false,
      emailForm: {
        email: "",
        password: "",
        newPass: "",
      },
    };
  },
  methods: {
    emailFun() {
      if (this.emailForm.email.length > 30)
        return this.$message(this.$t("email.limit"));
      if (!this.emailForm.email) return this.$message(this.$t("email.p_email"));
      if (
        !/^[A-Za-z0-9.\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          this.emailForm.email
        )
      )
        return this.$message(this.$t("email.err_email"));
      if (!this.emailForm.password)
        return this.$message(this.$t("pass.p_pass"));
      if (!this.emailForm.newPass)
        return this.$message(this.$t("pass.p_rePass"));
      if (this.emailForm.newPass !== this.emailForm.password)
        return this.$message(this.$t("pass.err_rePass"));
      this.emailShow = false;
      this.$emit("callBack", { type: "bindEmail", val: this.emailForm });
    },
    //绑定邮箱确认
    bindEmailFun(code) {
      this.$axios
        .post(this.$api.bindEmail, {
          email: this.emailForm.email,
          password: this.emailForm.password,
          code,
        }, { Serverid: this.$store.state.serverid })
        .then((res) => {
          this.$emit("callBack", { type: "HideCode", val: 1 });
        })
        .catch((err) => {
          this.$emit("callBack", { type: "HideCode", val: 0 });
        });
    },
  },
};
</script>

<style>
</style>